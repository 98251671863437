const env = {
    //Configuración global para todos los entornos
    "environment": "local",
    "appSettings": {
        "name": "Sistema de Administración Financiera Integral 2",
        "shortName": "SAFI 2.0",
        "version": "v1.12.4",
        "state": {
            "SAVING_PERIOD": 1000
        },
        "steps": {
            "creditControl": [
                "Instrumento legal",
                "Datos partida"
            ],
            "creditExecution": [
                "Expediente",
                "Partidas",
                "Afectaciones",
                "Comprobantes"
            ],
            "balanceTransfer": [
                "Expediente",
                "Partidas",
                "Parte 3",
                "Comprobantes"
            ],
            "exerciseEdition": [
                "Instrumento legal",
                "Ejercicio"
            ],
            "orderPayExecution": [
                "Expediente",
                "Orden de Pago"
            ],
            "compensation": [
                "Instrumento legal",
                "Partidas",
                "Comprobantes"
            ],
            "incorporation": [
                "Instrumento legal",
                "Partidas",
            ]
        },
        "DATE_FORMAT": "DD/MM/YYYY",
        "LEGAL_INSTRUMENTS_TYPES": [
            {
                "id": 4,
                "name": "DECRETO"
            },
            {
                "id": 7,
                "name": "DISPOSICIÓN"
            },
            {
                "id": 25,
                "name": "LEY"
            },
            {
                "id": 26,
                "name": "RESIDUOS PASIVOS"
            },
            {
                "id": 16,
                "name": "RESOLUCIÓN"
            }

        ],
        "SETTINGS_REPORTS": {
            "subCodeType": 2
        },
        //reCaptcha V2
        "googleSiteKey": "6Lc9nf4oAAAAAAKuAJJFp8n69d0EATkAd22IA_8h",
    },
	"apis": {
		"authApi": {
			"URL": "https://000000.api.auth.safi.misionescontaduria.com",
			"CLIENT_ID": 1,
			"APPLICATION_ID": 4,
			"AUTHENTICATION_ENDPOINT": "/authentication",
			"RESETPASSWORD_ENDPOINT": "/users/password",
			"HEADERS": {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			}
		},
        "presupuestoApi": {
            "URL": "https://000000.api.presupuesto.misionescontaduria.com/v1",
            "URL_V2": "https://000000.api.presupuesto.misionescontaduria.com/v2",
            "VERSION": "https://000000.api.presupuesto.misionescontaduria.com"
        },
        "coreApi": {
            "URL": "https://000000.coreapi.misionescontaduria.com"
        },
        "fundsRequestsApi": {
            "URL": "https://000000.api.fondos.misionescontaduria.com"

        },
		"proveedoresApi": {
			"URL": "https://proveedores.misiones.gob.ar:8080"
		}
	},
    "recaptchaAvailable": false
}

export const config = {...env, ...window["env"]}
